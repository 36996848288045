<template>
    <div>
        <div class="main-logout">
            <img style="height: 70px; width: auto;"
                src="https://c4ed.org/wp-content/uploads/2022/01/Artboard-5@figure1-2.png" alt="" class="logo">
            <button class="logout">
                <download-excel
                :data="filterData"
                :fields="excelFields"
                type="xlsx"
                name="JobApplications_export.xlsx"
                >
                    Export Data
                </download-excel>
            </button>
            <button class="logout" @click="logout">Logout</button>
        </div>
        <table>
            <thead>
                <tr>
                    <th><input type="checkbox" @click="selectAll($event)"></th>
                    <th @mouseover="setActiveColumn('jobId')">
                        <span v-if="activeColumn !== 'jobId'">Job ID</span>
                        <input v-else-if="activeColumn === 'jobId'" v-model="search.jobId" placeholder="Search by Job ID">
                    </th>
                    <th @mouseover="setActiveColumn('name')">
                        <span v-if="activeColumn !== 'name'">Name</span>
                        <input v-else-if="activeColumn === 'name'" v-model="search.name" placeholder="Search by Name">
                    </th>
                    <th @mouseover="setActiveColumn('email')">
                        <span v-if="activeColumn !== 'email'">E-mail</span>
                        <input v-else-if="activeColumn === 'email'" v-model="search.email" placeholder="Search by E-mail">
                    </th>
                    <th class="main-read" style="padding: 0;">
                        <a class='read' href='#' @click="markAsRead" v-if="update_ids.length > 0">
                            Mark as Read
                        </a><br />
                        <a class='read' href='#' @click="markAsUnread" v-if="update_ids.length > 0">
                            Mark as Unread
                        </a>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="JobApplication in filterData">
                    <td :class="{ unread: !JobApplication.read }"><input type="checkbox" :value="JobApplication.id"
                            @click="checkbox($event)" :checked="update_ids.includes(JobApplication.id)"></td>
                    <td data-title='Job ID'>
                        {{ JobApplication.A7 }}
                    </td>
                    <td data-title='Name'>
                        {{ JobApplication.firstName }} {{ JobApplication.middleName }} {{ JobApplication.surName }}
                    </td>
                    <td data-title='E-mail'>
                        {{ JobApplication.email }}
                    </td>
                    <td class='select'>
                        <a class='button' href='#' @click="selectJobApplication(JobApplication)">
                            View
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="detail" :class="{ open: isOpen }">
            <div class='detail-container'>
                <dl v-for="(value, key) in filteredJobApplication" :key="key">
                    <dt>
                        {{ filterKey(key) }}
                    </dt>
                    <dd v-if="isArray(value)">
                        <ul>
                            <li v-for="(item, index) in value" :key="index">{{ item }}</li>
                        </ul>
                    </dd>
                    <!-- isHttpLink -->
                    <dd v-else-if="isHttpLink(value)">
                        <a :href="value" class="attachment" target="blank">click here</a>
                    </dd>
                    <dd v-else-if="value">
                        {{ value }}
                    </dd>
                    <dd v-else="value">
                        N/A
                    </dd>
                </dl>
            </div>
            <div class='detail-nav'>
                <button class="close" @click="toggleDetail">Close</button>
            </div>
        </div>
    </div>
</template>

<script>
import router from '@/router';
import axios from 'axios';
import questions from '../../src/titles.json'

export default {
    name: 'DetailView',
    watch: {
        "search.jobId": {
            handler(value) {
                if (value == "") {
                    this.filterData = this.JobApplications
                } else {
                    this.filterData = this.JobApplications.filter(item =>
                        (value === '' || item.A7.toString().includes(value)))
                }
            }
        },
        "search.name": {
            handler(value) {
                if (value == "") {
                    this.filterData = this.JobApplications
                } else {
                    this.filterData = this.JobApplications.filter(item =>
                    (value === '' || [item.firstName, item.middleName, item.surName]
                        .join(' ')
                        .toLowerCase()
                        .includes(value.toLowerCase()))
                    )
                }
            }
        }, "search.email": {
            handler(value) {
                if (value == "") {
                    this.filterData = this.JobApplications
                } else {
                    this.filterData = this.JobApplications.filter(item =>
                        (value === '' || item.email.toString().includes(value)))
                }
            }
        },
    },

    data() {
        return {
            isOpen: false,
            JobApplications: [],
            filterData: [],
            update_ids: [],
            selectedJobApplication: {},
            activeColumn: '',
            search: {
                jobId: '',
                name: '',
                email: '',
            },
        }
    },
    computed: {
        filteredJobApplication() {
            const { id, ...filteredData } = this.selectedJobApplication;
            return filteredData;
        },
        excelFields() {
            return questions.reduce((result, item) => {
                result[item.value] = item.key;
                return result;
            }, {});
        },
    },
    methods: {
        selectAll(e) {
            if (e.target.checked) {
                this.update_ids = this.filterData.map(item => item.id)
            } else {
                this.update_ids = []
            }
        },
        checkbox(e) {
            if (e.target.checked) {
                this.update_ids.push(Number(e.target.value));
            } else {
                const index = this.update_ids.indexOf(Number(e.target.value));
                if (index > -1) {
                    // only splice array when item is found
                    this.update_ids.splice(index, 1); // 2nd parameter means remove one item only
                }
            }
        },
        setActiveColumn(column) {
            this.activeColumn = column;
        },
        toggleDetail() {
            this.isOpen = !this.isOpen;
        },
        getAllJobApplication() {
            const access_token = localStorage.getItem("access")
            const headers = {
                "Authorization": `Bearer ${access_token}`,
            };
            axios.get("https://api.careers.c4ed.org/get/job_application/", { headers }).then(response => {
                this.JobApplications = response.data
                this.filterData = this.JobApplications
            }
            )
        },
        selectJobApplication(jobApplication) {
            this.selectedJobApplication = jobApplication;
            this.selectedJobApplication.read = true
            const access_token = localStorage.getItem("access")
            const data = {
                job_ids: [jobApplication.id],
                status: true
            }
            const headers = {
                "Authorization": `Bearer ${access_token}`,
            };
            axios.post("https://api.careers.c4ed.org/job_read/", data, { headers })
            this.toggleDetail();
        },
        markAsRead() {
            const access_token = localStorage.getItem("access")
            const data = {
                job_ids: this.update_ids,
                status: true
            }
            const headers = {
                "Authorization": `Bearer ${access_token}`,
            };
            axios.post("https://api.careers.c4ed.org/job_read/", data, { headers }).then(reponse => {
                this.update_ids.map(item => {
                    this.filterData.find(i => {
                        if (item == i.id) {
                            i.read = true
                        }
                    })
                })
            })
        },
        markAsUnread() {
            const access_token = localStorage.getItem("access")
            const data = {
                job_ids: this.update_ids,
                status: false
            }
            const headers = {
                "Authorization": `Bearer ${access_token}`,
            };
            axios.post("https://api.careers.c4ed.org/job_read/", data, { headers }).then(reponse => {
                this.update_ids.map(item => {
                    this.filterData.find(i => {
                        if (item == i.id) {
                            i.read = false
                        }
                    })
                })
            })
        },
        logout() {
            const access_token = localStorage.getItem("access")
            const refresh_token = localStorage.getItem("refresh")
            const headers = {
                "Authorization": `Bearer ${access_token}`,
            };
            axios.post(`https://api.careers.c4ed.org/api/token/blacklist/`, { "refresh": refresh_token }, { headers }).then(response => {
                localStorage.removeItem('access')
                localStorage.removeItem('refresh')
                router.push("/login")
            }).catch(e => {
                console.log(e)
            })
        },
        filterKey(key) {
            const foundItem = questions.find(item => item.key === key);
            if (foundItem) {
                return foundItem.value;
            } else {
                return null;
            }
        },
        isArray(value) {
            return Array.isArray(value);
        },
        isHttpLink(value) {
            return /^https?:\/\//.test(value);
        }
    },
    mounted() {
        const access_token = localStorage.getItem("access")
        const refresh_token = localStorage.getItem("refresh")
        if (access_token && refresh_token) {
            const headers = {
                "Authorization": `Bearer ${access_token}`,
            };
            axios.get("https://api.careers.c4ed.org/me/", { headers }).then().catch(e => {
                axios.post("https://api.careers.c4ed.org/api/token/refresh/", { "refresh": refresh_token }).then(response => {
                    localStorage.setItem('access', response.data.access)
                }).catch(e => {
                    router.push("/login")
                })
            })
            this.getAllJobApplication()
        }
        else {
            router.push("/login")
        }
    }
}
</script>
<style scoped>
* {
    box-sizing: border-box;
}

html.open,
body.open {
    height: 100%;
    overflow: hidden;
}

.unread {
    border-left: 5px solid #73cee2;
}

.attachment {
    text-decoration: none;
    color: inherit;
    display: inline-block;
    padding: 10px 20px;
    /* Adjust padding as needed */
    font-size: 16px;
    /* Adjust font size as needed */
    border: 2px solid #3498db;
    /* Button border color */
    background-color: #3498db;
    /* Button background color */
    color: #fff;
    /* Button text color */
    border-radius: 5px;
    /* Optional: Round the corners */
    transition: background-color 0.3s, color 0.3s;
    /* Smooth transition on hover */
}

/* Change styles on hover */
.attachment:hover {
    background-color: #2980b9;
    /* Button background color on hover */
    border-color: #2980b9;
    /* Button border color on hover */
    color: #fff;
    /* Button text color on hover */
}

html {
    /* padding: 2.5%; */
    /* font-size: 62.5%; */
    margin-top: 0;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

li {
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
}

/* Responsive styles */
@media (max-width: 768px) {
    li {
        width: 100%;
    }
}

body {
    margin-top: 0;
    /* padding: 2.5%; */
    background-color: #5BB9B8;
    line-height: 1.6;
    -webkit-font-smoothing: antialiased;
    color: #fff;
    font-size: 1.6rem;
    font-family: 'Lato', sans-serif;
}

p {
    text-align: center;
    margin: 2.5% 0 5%;
}

main {
    background-color: #2C3845;
}

.logo {
    float: left;
    margin: 10px;
}

.logout {
    margin: 35px 10px 0 0;
    padding: 1%;
    background-color: #076a6e;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 10px;
    position: relative;
}

.main-logout {
    /* justify-content: right; */
    /* align-items: right; */
    text-align: right;
}

h1 {
    text-align: center;
    font-weight: 300;
}

table {
    width: 100%;
    border-collapse: collapse;
    /* margin-top: 2.5%; */
}

tr,
td {
    padding: 10px;
    text-align: center;
}

td:before {
    content: attr(data-title);
    color: #7a91aa;
    text-transform: uppercase;
    font-size: 1.4rem;
    padding-right: 10px;
    display: block;
}

th {
    text-align: left;
    font-weight: 700;
}

thead th {
    background-color: #076a6e;
    color: #fff;
    border: 1px solid #076a6e;
}

/* thead th:hover {
    padding: 0;
} */
tfoot th {
    display: block;
    padding: 10px;
    text-align: center;
    color: #b8c4d2;
}

.button {
    line-height: 1;
    display: inline-block;
    font-size: 1.2rem;
    text-decoration: none;
    border-radius: 5px;
    color: #fff;
    padding: 8px;
    background-color: #4b908f;
}

.select {
    padding-bottom: 2.5%;
    border-bottom: 1px solid #28333f;
}

.select:before {
    display: none;
}

.detail {
    background-color: #076a6e;
    width: 100%;
    height: 100%;
    padding: 2.5% 0;
    position: fixed;
    color: #fff;
    top: 0;
    left: 0;
    overflow: auto;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
}

.detail.open {
    transform: translateX(0);
}

.detail-container {
    margin: 0 10px;
    padding: 2.5%;
    width: auto;
    font-size: 10px;
}

dl {
    margin: 0;
    padding: 0;
}

dt {
    font-size: 1.5rem;
    font-weight: 300;
}

dd {
    margin: 0 0 2.5% 0;
    font-size: 1rem;
    padding-bottom: 5px;
    border-bottom: 1px solid #fff;
    box-shadow: 0 1px 0 #fff;
}

.close {
    background: none;
    padding: 1.8%;
    color: #fff;
    font-weight: 300;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 4px;
    line-height: 1;
    font-size: 1.8rem;
    position: fixed;
    right: 2.5%;
    top: 2.5%;
    transition: border 0.3s linear;
}

.close:hover,
.close:focus {
    color: #076a6e;
    background-color: #fff;
    border: 1px solid #076a6e;
}

@media (max-width: 768px) {

    html,
    body {
        padding: 0;
        margin: 0;
    }

    p {
        margin: 2.5% 0;
    }

    .logout {
        /* top: 10%; */
        right: 10%;
    }

    .select {
        padding-left: 2.5%;
    }

    .detail-container {
        padding: 2.5%;
    }
}

input {
    width: 100%;
    height: 100%;
    border: 1px solid #ffff;
    outline: none;
    color: #ffff;
    background-color: #076a6e;
    padding: 10px;
}

::placeholder {
    color: #fff
}

.main-read {
    width: 100px;
}

.read {
    display: inline-block;
    font-size: 0.6rem;
    text-decoration: none;
    border-radius: 5px;
    color: #fff;
    background-color: #4b908f;
    padding: 3.5px;
}

@media (min-width: 460px) {
    td {
        text-align: left;
    }

    td:before {
        display: inline-block;
        text-align: right;
        width: 140px;
    }

    .select {
        padding-left: 160px;
    }
}

@media (min-width: 720px) {
    table {
        display: table;
    }

    tr {
        display: table-row;
    }

    td,
    th {
        display: table-cell;
    }

    tbody {
        display: table-row-group;
    }

    thead {
        display: table-header-group;
    }

    tfoot {
        display: table-footer-group;
    }

    td {
        border: 1px solid #28333f;
    }

    td:before {
        display: none;
    }

    td,
    th {
        padding: 10px;
    }

    tr:nth-child(2n+2) td {
        background-color: #076a6e;
        color: #fff;
    }

    tfoot th {
        display: table-cell;
    }

    .select {
        padding: 10px;
    }
}
</style>