import { createWebHistory, createRouter } from "vue-router";
import LoginPage from "../components/LoginPage.vue"
import LandingPage from "../components/LandingPage.vue"
import DetailView from "../components/DetailView.vue"


const routes = [
    {
      path: "/",
      name: "LandingPage",
      component: LandingPage,
    },
    {
      path: "/login",
      name: "LoginPage",
      component: LoginPage,
    },
    {
        path: "/detail",
        name: "DetailView",
        component: DetailView,
      },
  ];


const router = createRouter({
    history: createWebHistory(),
    routes,
  });
  
  export default router;