<template>
  <div class="loading" v-if="isLoading">Loading&#8230;</div>
  <div class="container">
    <h1>C4ED Job Applicants & External Experts Questionnaire</h1>
    <h3>A. General</h3>
    <label>
      <span>Surname</span>
    </label>
    <div>
      <input id="surname" v-model="surName" name="surname" :class="{ danger: surNameError }" />
      <span v-if="surNameError" style="color: red">{{ surNameError }}</span>
    </div>

    <label>
      <span class="span-normal">Middle Name</span>
    </label>
    <div class="">
      <input id="middlename" v-model="middleName" name="middlename" :class="{ danger: middleNameError }" />
      <span v-if="middleNameError" style="color: red">{{ middleNameError }}</span>
    </div>

    <label>
      <span class="span-normal">First Name</span>
    </label>
    <div class="">
      <input id="firstname" v-model="firstName" name="firstname" :class="{ danger: firstNameError }" />
      <span v-if="firstNameError" style="color: red">{{ firstNameError }}</span>
    </div>

    <label>
      <span class="span-normal">Email</span>
    </label>
    <div class="">
      <input id="email" v-model="email" name="email" :class="{ danger: emailError }" />
      <span v-if="emailError" style="color: red">{{ emailError }}</span>
    </div>

    <label>
      <span class="span-normal">Gender</span>
    </label>
    <div class="">
      <select name="gender" :class="{ danger: genderError }" v-model="gender" id="gender">
        <option value="Male">Male</option>
        <option value="Female">Female</option>
        <option value="Diverse">Diverse</option>
      </select>
      <span v-if="genderError" style="color: red">{{ genderError }}</span>
    </div>

    <label>
      <span class="span-normal">Date of Birth</span>
    </label>
    <div class="">
      <input type="date" v-model="DOB" id="birthday" name="birthday" :class="{ danger: DOBError }" />
      <span v-if="DOBError" style="color: red">{{ DOBError }}</span>
    </div>

    <label>
      <span class="span-normal">A7. What is the Job ID of the position for which you are
        applying?*</span>
      <span class="span-grey">For example (J2300XX). The Job ID can be found on the job announcement.
        If you are not applying for a currently advertised job position, put
        "Unsolicited Quantitative Research Manager", "Unsolicited Monitoring and
        Evaluation Specialist", "Consultant" or similar.</span>
    </label>
    <div class="">
      <input type="text" v-model="A7" :disabled="A7Disable" :class="{ danger: A7Error }" />
      <span v-if="A7Error" style="color: red">{{ A7Error }}</span>
    </div>

    <label>
      <span class="span-normal">A8. What is your nationality? *</span>
    </label>
    <div class="">
      <input id="nationality" v-model="A8" name="nationality" :class="{ danger: A8Error }" />
      <span v-if="A8Error" style="color: red">{{ A8Error }}</span>
    </div>

    <label>
      <span class="span-normal">A9. Where are you currently living? (city, country) *</span>
    </label>
    <div class="">
      <input id="city_country" v-model="A9" name="city_country" :class="{ danger: A9Error }" />
      <span v-if="A9Error" style="color: red">{{ A9Error }}</span>
    </div>

    <h3>B. Language Skills</h3>

    <label>
      <span class="span-normal">B1. English language skills</span>
    </label>
    <div class="">
      <select name="english_skill" v-model="B1" id="english_skill" :class="{ danger: B1Error }">
        <option value="No Skills">No Skills</option>
        <option value="Basic">Basic</option>
        <option value="Intermediate">Intermediate</option>
        <option value="Advanced">Advanced</option>
        <option value="Proficient">Proficient</option>
        <option value="Fluent">Fluent</option>
        <option value="Native Speaker">Native Speaker</option>
      </select>
      <span v-if="B1Error" style="color: red">{{ B1Error }}</span>
    </div>

    <label>
      <span class="span-normal">B2. Spanish language skills</span>
    </label>
    <div class="">
      <select name="spanish_skill" v-model="B2" id="spanish_skill" :class="{ danger: B2Error }">
        <option value="No Skills">No Skills</option>
        <option value="Basic">Basic</option>
        <option value="Intermediate">Intermediate</option>
        <option value="Advanced">Advanced</option>
        <option value="Proficient">Proficient</option>
        <option value="Fluent">Fluent</option>
        <option value="Native Speaker">Native Speaker</option>
      </select>
      <span v-if="B2Error" style="color: red">{{ B2Error }}</span>
    </div>

    <label>
      <span class="span-normal">B3. French language skills</span>
    </label>
    <div class="">
      <select name="french_skill" v-model="B3" id="french_skill" :class="{ danger: B3Error }">
        <option value="No Skills">No Skills</option>
        <option value="Basic">Basic</option>
        <option value="Intermediate">Intermediate</option>
        <option value="Advanced">Advanced</option>
        <option value="Proficient">Proficient</option>
        <option value="Fluent">Fluent</option>
        <option value="Native Speaker">Native Speaker</option>
      </select>
      <span v-if="B3Error" style="color: red">{{ B3Error }}</span>
    </div>

    <label>
      <span class="span-normal">B4. Portuguese language skills</span>
    </label>
    <div class="">
      <select name="portuguese_skill" v-model="B4" id="portuguese_skill" :class="{danger: B4Error}" >
        <option value="No Skills">No Skills</option>
        <option value="Basic">Basic</option>
        <option value="Intermediate">Intermediate</option>
        <option value="Advanced">Advanced</option>
        <option value="Proficient">Proficient</option>
        <option value="Fluent">Fluent</option>
        <option value="Native Speaker">Native Speaker</option>
      </select>
      <span v-if="B4Error" style="color: red">{{ B4Error }}</span>
    </div>

    <label>
      <span class="span-normal">B5. German language skills</span>
    </label>
    <div class="">
      <select name="german_skill" v-model="B5" id="german_skill" :class="{danger: B5Error}">
        <option value="No Skills">No Skills</option>
        <option value="Basic">Basic</option>
        <option value="Intermediate">Intermediate</option>
        <option value="Advanced">Advanced</option>
        <option value="Proficient">Proficient</option>
        <option value="Fluent">Fluent</option>
        <option value="Native Speaker">Native Speaker</option>
      </select>
      <span v-if="B5Error" style="color: red">{{ B5Error }}</span>
    </div>

    <label>
      <span class="span-normal">B6. Russian language skills</span>
    </label>
    <div class="">
      <select name="russian_skill" v-model="B6" id="russian_skill" :class="{danger: B6Error}">
        <option value="No Skills">No Skills</option>
        <option value="Basic">Basic</option>
        <option value="Intermediate">Intermediate</option>
        <option value="Advanced">Advanced</option>
        <option value="Proficient">Proficient</option>
        <option value="Fluent">Fluent</option>
        <option value="Native Speaker">Native Speaker</option>
      </select>
      <span v-if="B6Error" style="color: red">{{ B6Error }}</span>
    </div>

    <label>
      <span class="span-normal" :class="{danger: B7Error}">B7. Please enter additional language skills here:</span>
    </label>
    <div class="">
      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="afrikaans" name="afrikaans" value="Afrikaans" />
        <label for="afrikaans">Afrikaans</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="amharic" name="amharic" value="Amharic" />
        <label for="amharic">Amharic</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="ani" name="ani" value="Ani" />
        <label for="ani">Ani</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="arabic" name="arabic" value="Arabic" />
        <label for="arabic">Arabic</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="assamese" name="assamese" value="Assamese" />
        <label for="assamese">Assamese</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="balochi" name="balochi" value="Balochi" />
        <label for="balochi">Balochi</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="bamanankan" name="bamanankan" value="Bamanankan" />
        <label for="bamanankan">Bamanankan</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="bangla" name="bangla" value="Bangla" />
        <label for="bangla">Bangla</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="bemba" name="bemba" value="Bemba" />
        <label for="bemba">Bemba</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="bissa" name="bissa" value="Bissa" />
        <label for="bissa">Bissa</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="danish" name="danish" value="Danish" />
        <label for="danish">Danish</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="dendi" name="dendi" value="Dendi" />
        <label for="dendi">Dendi</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="dutch" name="dutch" value="Dutch" />
        <label for="dutch">Dutch</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="ekegusi" name="ekegusi" value="Ekegusi" />
        <label for="ekegusi">Ekegusi</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="ewe" name="ewe" value="Ewe" />
        <label for="ewe">Ewe</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="filipino" name="filipino" value="Filipino" />
        <label for="filipino">Filipino</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="fon" name="fon" value="Fon" />
        <label for="fon">Fon</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="ghomala" name="ghomala" value="Ghomalà" />
        <label for="ghomala">Ghomalà</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="goun" name="goun" value="Goun" />
        <label for="goun">Goun</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="hausa" name="hausa" value="Hausa" />
        <label for="hausa">Hausa</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="hindi" name="hindi" value="Hindi" />
        <label for="hindi">Hindi</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="indonesian" name="indonesian" value="Indonesian" />
        <label for="indonesian">Indonesian</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="isixhosa" name="isixhosa" value="IsiXhosa" />
        <label for="isixhosa">IsiXhosa</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="isizulu" name="isizulu" value="IsiZulu" />
        <label for="isizulu">IsiZulu</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="italian" name="italian" value="Italian" />
        <label for="italian">Italian</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="japanese" name="japanese" value="Japanese" />
        <label for="japanese">Japanese</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="kannada" name="kannada" value="Kannada" />
        <label for="kannada">Kannada</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="kazakh" name="kazakh" value="Kazakh" />
        <label for="kazakh">Kazakh</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="kiswahili" name="kiswahili" value="Kiswahil" />
        <label for="kiswahili">Kiswahil</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="kyrgyz" name="kyrgyz" value="Kyrgyz" />
        <label for="kyrgyz">Kyrgyz</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="luganda" name="luganda" value="Luganda" />
        <label for="luganda">Luganda</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="lukiga" name="lukiga" value="Lukiga" />
        <label for="lukiga">Lukiga</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="lusonga" name="lusonga" value="Lusonga" />
        <label for="lusonga">Lusonga</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="mandarin" name="mandarin" value="Mandarin" />
        <label for="mandarin">Mandarin</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="mauritian_creole" name="mauritian_creole"
          value="Mauritian Creole" />
        <label for="mauritian_creole">Mauritian Creole</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="mina" name="mina" value="Mina" />
        <label for="mina">Mina</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="moore" name="moore" value="Mooré" />
        <label for="mooré">Mooré</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="nago" name="nago" value="Nago" />
        <label for="nago">Nago</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="ndebele" name="languages" value="Ndebele" />
        <label for="ndebele">Ndebele</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="nepali" name="nepali" value="Nepali" />
        <label for="nepali">Nepali</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="norwegian" name="norwegian" value="Norwegian" />
        <label for="norwegian">Norwegian</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="nyanja" name="nyanja" value="Nyanja" />
        <label for="nyanja">Nyanja</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="others" name="others" value="Others" />
        <label for="others">Others</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="pashto" name="pashto" value="Pashto" />
        <label for="pashto">Pashto</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="persian" name="persian" value="Persian" />
        <label for="persian">Persian</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="punjabi" name="punjabi" value="Punjabi" />
        <label for="punjabi">Punjabi</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="runyankolo" name="runyankolo" value="Runyankolo" />
        <label for="runyankolo">Runyankolo</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="runyoro" name="runyoro" value="Runyoro" />
        <label for="runyoro">Runyoro</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="rutoro" name="rutoro" value="Rutoro" />
        <label for="rutoro">Rutoro</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="sindhi" name="sindhi" value="Sindhi" />
        <label for="sindhi">Sindhi</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="somali" name="somali" value="Somali" />
        <label for="somali">Somali</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="sotho" name="sotho" value="Sotho" />
        <label for="sotho">Sotho</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="swahili" name="swahili" value="Swahili" />
        <label for="swahili">Swahili</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="swedish" name="swedish" value="Swedish" />
        <label for="swedish">Swedish</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="telugu" name="telugu" value="Telugu" />
        <label for="telugu">Telugu</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="thai" name="thai" value="Thai" />
        <label for="thai">Thai</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="tigrigna" name="tigrigna" value="Tigrigna" />
        <label for="tigrigna">Tigrigna</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="tonga" name="tonga" value="Tonga" />
        <label for="tonga">Tonga</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="turkish" name="turkish" value="Turkish" />
        <label for="turkish">Turkish</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="urdu" name="urdu" value="Urdu" />
        <label for="urdu">Urdu</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="uzbek" name="uzbek" value="Uzbek" />
        <label for="uzbek">Uzbek</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="wolof" name="wolof" value="Wolof" />
        <label for="wolof">Wolof</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="yoruba" name="yoruba" value="Yoruba" />
        <label for="yoruba">Yoruba</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="vietnamese" name="vietnamese" value="Vietnamese" />
        <label for="vietnamese">Vietnamese</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(B7, $event)" id="other" name="other" value="other"/>
        <label for="other">Other (specify below)</label><br />
      </div>

      <span v-if="B7Error" style="color: red">{{ B7Error }}</span>
    </div>

    <label>
      <span class="span-normal">B8. Languages (other unlisted)</span>
      <span class="span-grey">If you selected "other" languages in question B7, please specify them
        here</span>
    </label>
    <div class="">
      <input id="other_language" name="other_language" v-model="B8" />
      <span v-if="B8Error" style="color: red">{{ B8Error }}</span>
    </div>

    <h3>C. Education</h3>

    <label>
      <span class="span-normal">C1. What is the highest degree that you have obtained to date (or are
        about to obtain in the next 12 months)? *</span>
    </label>
    <div class="">
      <div class="checkbox-container">
        <input type="checkbox" id="bachelor" name="bachelor" value="Bachelors Degree" @click="checkbox(C1, $event)" />
        <label for="bachelor">Bachelors Degree</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(C1, $event)" id="master" name="master" value="Masters Degree" />
        <label for="master">Masters Degree</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(C1, $event)" id="phd" name="phd" value="PhD" />
        <label for="phd">PhD</label><br />
      </div>

      <span v-if="C1Error" style="color: red">{{ C1Error }}</span>
    </div>

    <label>
      <span class="span-normal">C2. In which discipline(s) (will) have you obtained the highest
        degree(s)? *</span>
    </label>
    <div class="">
      <div class="checkbox-container">
        <input type="checkbox" id="economics" name="economics" value="Economics" @click="checkbox(C2, $event)" />
        <label for="economics">Economics</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" id="political_science" name="political_science" value="Political Science"
          @click="checkbox(C2, $event)" />
        <label for="political_science">Political Science</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" id="sociology" name="sociology" value="Sociology" @click="checkbox(C2, $event)" />
        <label for="phd">Sociology</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" id="data_science" name="data_science" value="Data Science" @click="checkbox(C2, $event)" />
        <label for="data_science">Data Science</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" id="social_science" name="social_science" value="Social Science"
          @click="checkbox(C2, $event)" />
        <label for="phd">Social Science</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" id="int_development" name="int_development" value="International Development"
          @click="checkbox(C2, $event)" />
        <label for="int_development">International Development</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" id="anthropology" name="anthropology" value="Anthropology" @click="checkbox(C2, $event)" />
        <label for="anthropology">Anthropology</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" id="other_discipline" name="other_discipline" value="Other Discipline" @click="checkbox(C2, $event)"/>
        <label for="other_discipline">Other</label><br />
      </div>

      <span v-if="C2Error" style="color: red">{{ C2Error }}</span>
    </div>

    <label>
      <span class="span-normal">C3. If other discipline, please enter here:</span>
    </label>
    <div class="">
      <input id="other_discipline" v-model="C3" name="other_discipline" :class="{danger: C3Error}"/>
      <span v-if="C3Error" style="color: red">{{ C3Error }}</span>
    </div>

    <label>
      <span class="span-normal">C4. What university (will) have you obtained your highest degree from?
        *</span>
    </label>
    <div class="">
      <input id="university_name" v-model="C4" name="university_name" :class="{danger: C4Error}"/>
      <span v-if="C4Error" style="color: red">{{ C4Error }}</span>
    </div>

    <label>
      <span class="span-normal">C5. When (will) have you completed this degree (year)? *</span>
    </label>
    <div class="">
      <input type="date" id="edu_complete_date" v-model="C5" name="edu_complete_date" :class="{danger: C5Error}"/>
      <span v-if="C5Error" style="color: red">{{ C5Error }}</span>
    </div>

    <label>
      <span class="span-normal">C6. Other qualifications (further training)</span>
    </label>
    <div class="">
      <input id="other_qualifications" v-model="C6" name="other_qualifications" />
      <span v-if="C6Error" style="color: red">{{ C6Error }}</span>
    </div>

    <h3>D. Professional experience</h3>

    <label>
      <span class="span-normal">D1. Professional experience (in years)</span>
    </label>
    <div class="">
      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(D1, $event)" id="exp_0_1" name="exp_0_1" value="0 - 1" />
        <label for="exp_0_1">0 - 1</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(D1, $event)" id="exp_2_5" name="exp_2_5" value="2 - 5" />
        <label for="exp_2_5">2 - 5</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(D1, $event)" id="exp_5_10" name="exp_5_10" value="5 - 10" />
        <label for="exp_5_10">5 - 10</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(D1, $event)" id="exp_10_over" name="exp_10_over"
          value="More than 10 years" />
        <label for="exp_10_over">More than 10 years</label><br />
      </div>

      <span v-if="D1Error" style="color: red">{{ D1Error }}</span>
    </div>

    <label>
      <span class="span-normal">D2. I have working experience in the following countries (1 of
        8):</span>
    </label>
    <div class="">
      <select id="exp_country_1" name="exp_country_1" v-model="D2" :class="{danger: D2Error}">
        <option v-for="country in countries" :value="country">
          {{ country }}
        </option>
      </select>
      <span v-if="D2Error" style="color: red">{{ D2Error }}</span>
    </div>

    <label>
      <span class="span-normal">D3. I have working experience in the following countries (2 of
        8):</span>
    </label>
    <div class="">
      <select id="exp_country_2" name="exp_country_2" v-model="D3" :class="{danger: D3Error}">
        <option v-for="country in countries" :value="country">
          {{ country }}
        </option>
      </select>
      <span v-if="D3Error" style="color: red">{{ D3Error }}</span>
    </div>

    <label>
      <span class="span-normal">D4. I have working experience in the following countries (3 of
        8):</span>
    </label>
    <div class="">
      <select id="exp_country_3" name="exp_country_3" v-model="D4" :class="{danger: D4Error}">
        <option v-for="country in countries" :value="country">
          {{ country }}
        </option>
      </select>
      <span v-if="D4Error" style="color: red">{{ D4Error }}</span>
    </div>

    <label>
      <span class="span-normal">D5. I have working experience in the following countries (4 of
        8):</span>
    </label>
    <div class="">
      <select id="exp_country_4" name="exp_country_4" v-model="D5" :class="{danger: D5Error}">
        <option v-for="country in countries" :value="country">
          {{ country }}
        </option>
      </select>
      <span v-if="D5Error" style="color: red">{{ D5Error }}</span>
    </div>

    <label>
      <span class="span-normal">D6. I have working experience in the following countries (5 of
        8):</span>
    </label>
    <div class="">
      <select id="exp_country_5" name="exp_country_5" v-model="D6" :class="{danger: D6Error}">
        <option v-for="country in countries" :value="country">
          {{ country }}
        </option>
      </select>
      <span v-if="D6Error" style="color: red">{{ D6Error }}</span>
    </div>

    <label>
      <span class="span-normal">D7. I have working experience in the following countries (6 of
        8):</span>
    </label>
    <div class="">
      <select id="exp_country_6" name="exp_country_6" v-model="D7" :class="{danger: D7Error}">
        <option v-for="country in countries" :value="country">
          {{ country }}
        </option>
      </select>
      <span v-if="D7Error" style="color: red">{{ D7Error }}</span>
    </div>

    <label>
      <span class="span-normal">D8. I have working experience in the following countries (7 of
        8):</span>
      <span class="span-grey">Please add additional countries here: (free editable)</span>
    </label>
    <div class="">
      <input id="exp_country_7" name="exp_country_7" v-model="D8" :class="{danger: D8Error}"/>
      <span v-if="D8Error" style="color: red">{{ D8Error }}</span>
    </div>

    <label>
      <span class="span-normal">D9. I have working experience in the following countries (8 of
        8):</span>
      <span class="span-grey">Please add additional countries here: (free editable)</span>
    </label>
    <div class="">
      <input id="exp_country_8" name="exp_country_8" v-model="D9" :class="{danger: D9Error}"/>
      <span v-if="D9Error" style="color: red">{{ D9Error }}</span>
    </div>

    <label>
      <span class="span-normal">D10. I have qualifications and/or working experience in the following
        development areas:</span>
    </label>
    <div class="">
      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(D10, $event)" id="ea_health" name="ea_health" value="Health" />
        <label for="ea_health">Health</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(D10, $event)" id="ea_nutrition" name="ea_nutrition" value="Nutrition" />
        <label for="ea_nutrition">Nutrition</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(D10, $event)" id="ea_labour" name="ea_labour" value="ea_labour" />
        <label for="ea_labour">Labour</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(D10, $event)" id="ea_mf_credit" name="ea_mf_credit"
          value="Microfinance, credit, saving schemes" />
        <label for="ea_mf_credit">Microfinance, credit, saving schemes</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(D10, $event)" id="ea_sp_ct" name="ea_sp_ct"
          value="Social Protection, cash transfer" />
        <label for="ea_sp_ct">Social Protection, cash transfer</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(D10, $event)" id="ea_rural_dev" name="ea_rural_dev"
          value="Rural Development" />
        <label for="ea_rural_dev">Rural Development</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(D10, $event)" id="ea_agriculture" name="ea_agriculture"
          value="Agriculture" />
        <label for="ea_agriculture">Agriculture</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(D10, $event)" id="ea_comm_mob" name="ea_comm_mob"
          value="Community Mobilization" />
        <label for="ea_comm_mob">Community Mobilization</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(D10, $event)" id="ea_peace_build" name="ea_peace_build"
          value="Peace Building" />
        <label for="ea_peace_build">Peace Building</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(D10, $event)" id="ea_empower_af" name="ea_empower_af"
          value="Empowerment (adolescent or female)" />
        <label for="ea_empower_af">Empowerment (adolescent or female)</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(D10, $event)" id="ea_enrm" name="ea_enrm"
          value="Environment natural resource management" />
        <label for="ea_enrm">Environment, natural resource management</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(D10, $event)" id="ea_climate" name="ea_climate"
          value="Climate change mitigation adaption" />
        <label for="ea_climate">Climate change mitigation, adaption</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(D10, $event)" id="ea_child_labour" name="ea_child_labour"
          value="Child Labor" />
        <label for="ea_child_labour">Child Labor</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(D10, $event)" id="ea_child_youth_rights" name="ea_child_youth_rights"
          value="Child and youth rights" />
        <label for="ea_child_youth_rights">Child and youth rights</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(D10, $event)" id="ea_edu_access" name="ea_edu_access"
          value="Education access quality and attainment" />
        <label for="ea_edu_access">Education access, quality and attainment</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(D10, $event)" id="ea_migration" name="ea_migration" value="Migration" />
        <label for="ea_migration">Migration</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(D10, $event)" id="ea_social_network" name="ea_social_network"
          value="Social networks and behavior" />
        <label for="ea_social_network">Social networks and behavior</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(D10, $event)" id="ea_social_mobility" name="ea_social_mobility"
          value="Social mobility" />
        <label for="ea_social_mobility">Social mobility</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(D10, $event)" id="ea_poverty" name="ea_poverty" value="Poverty (measurement, alleviation, depriviation, inequality,
          PPG/inclusive development)" />
        <label for="ea_poverty">Poverty (measurement, alleviation, depriviation, inequality,
          PPG/inclusive development)</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(D10, $event)" id="ea_trade" name="ea_trade" value="Trade" />
        <label for="ea_trade">Trade</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(D10, $event)" id="ea_overarching" name="ea_overarching"
          value="Overarching" />
        <label for="ea_overarching">Overarching</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(D10, $event)" id="ea_other" name="ea_other" value="Other" />
        <label for="ea_other">Other</label><br />
      </div>

      <span v-if="D10Error" style="color: red">{{ D10Error }}</span>
    </div>

    <h3>E. Experience in proposal writing</h3>

    <label>
      <span class="span-normal">E1. Do you have any experience in fund raising and grant proposals
        writing?</span>
    </label>
    <div class="">
      <select name="prop_frgp" v-model="E1" id="prop_frgp" :class="{danger: E1Error}">
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
      <span v-if="E1Error" style="color: red">{{ E1Error }}</span>
    </div>

    <label>
      <span class="span-normal">E2. Was at least one proposal successful and funding granted?</span>
    </label>
    <div class="">
      <select name="prop_funding_grant" v-model="E2" id="prop_funding_grant" :class="{danger: E2Error}">
        <option value="Yes">Yes</option>
        <option value="No">No</option>
        <option value="Not Applicable">Not Applicable</option>
      </select>
      <span v-if="E2Error" style="color: red">{{ E2Error }}</span>
    </div>

    <h3>F. Data collection experience</h3>

    <label>
      <span class="span-normal">F1. Have you ever collected survey/primary data in the Global
        South?</span>
    </label>
    <div class="">
      <select name="dc_gs" id="dc_gs" v-model="F1" :class="{danger: F1Error}">
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
      <span v-if="F1Error" style="color: red">{{ F1Error }}</span>
    </div>

    <label>
      <span class="span-normal">F2. What was the largest survey sample you collected data on, if
        applicable (number of observations / respondents)? *</span>
    </label>
    <div class="">
      <input id="dc_largest_survey" v-model="F2" name="dc_largest_survey" :class="{danger: F2Error}"/>
      <span v-if="F2Error" style="color: red">{{ F2Error }}</span>
    </div>

    <label>
      <span class="span-normal" >F3. What method of data collection do you have experience with?</span>
    </label>
    <div class="">
      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(F3, $event)" id="dc_hh" name="dc_hh" value="Household survey" />
        <label for="dc_hh">Household survey</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(F3, $event)" id="dc_ent" name="dc_ent" value="Enterprise survey" />
        <label for="dc_ent">Enterprise survey</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(F3, $event)" id="dc_fg" name="dc_fg" value="Focus group discussion" />
        <label for="dc_fg">Focus group discussion</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(F3, $event)" id="dc_kii" name="dc_kii"
          value="In-depth or key informant interviews" />
        <label for="dc_kii">In-depth or key informant interviews</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(F3, $event)" id="dc_pm" name="dc_pm" value="Participatory methods" />
        <label for="dc_pm">Participatory methods</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(F3, $event)" id="dc_no_exp" name="dc_no_exp" value="No experience" />
        <label for="dc_no_exp">No experience</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(F3, $event)" id="dc_other" name="dc_other" value="Other (specify below)" />
        <label for="dc_other">Other (specify below)</label><br />
      </div>

      <span v-if="F3Error" style="color: red">{{ F3Error }}</span>
    </div>

    <label>
      <span class="span-normal">F4. Other method(s) of data collection</span>
    </label>
    <div class="">
      <input id="dc_other_specify" v-model="F4" name="dc_other_specify" :class="{danger: F4Error}"/>
      <span v-if="F4Error" style="color: red">{{ F4Error }}</span>
    </div>

    <h3>G. Evaluation experience</h3>

    <label>
      <span class="span-normal">G1. Have you ever been involved in a rigorous impact evaluation
        (RIE)?</span>
    </label>
    <div class="">
      <select name="ee_impact_eval" v-model="G1" id="ee_impact_eval" :class="{danger: G1Error}">
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
      <span v-if="G1Error" style="color: red">{{ G1Error }}</span>
    </div>

    <label>
      <span class="span-normal">G2. Briefly describe your experience with impact evaluations</span>
    </label>
    <div class="">
      <input id="ee_exp_desc" v-model="G2" name="ee_exp_desc" :class="{danger: G2Error}"/>
      <span v-if="G2Error" style="color: red">{{ G2Error }}</span>
    </div>

    <label>
      <span class="span-normal">G3. Have you ever been involved in a process evaluation? *</span>
    </label>
    <div class="">
      <select name="ee_process_eval" v-model="G3" id="ee_process_eval" :class="{danger: G3Error}">
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
      <span v-if="G3Error" style="color: red">{{ G3Error }}</span>
    </div>

    <label>
      <span class="span-normal">G4. Briefly describe your experience with process evaluations</span>
    </label>
    <div class="">
      <input id="ee_exp_process_eval" v-model="G4" name="ee_exp_process_eval" :class="{danger: G4Error}"/>
      <span v-if="G4Error" style="color: red">{{ G4Error }}</span>
    </div>

    <label>
      <span class="span-normal">G5. Have you ever been involved in a formative evaluation? *</span>
    </label>
    <div class="">
      <select name="ee_formative_eval" v-model="G5" id="ee_formative_eval" :class="{danger: G5Error}">
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
      <span v-if="G5Error" style="color: red">{{ G5Error }}</span>
    </div>

    <label>
      <span class="span-normal">G6. Briefly describe your experience with formative evaluations</span>
    </label>
    <div class="">
      <input id="ee_desc_formative_eval" v-model="G6" name="ee_desc_formative_eval" :class="{danger: G6Error}"/>
      <span v-if="G6Error" style="color: red">{{ G6Error }}</span>
    </div>

    <label>
      <span class="span-normal">G7. Have you been involved in any other type of evaluations?</span>
    </label>
    <div class="">
      <select name="ee_other_eval" v-model="G7" id="ee_other_eval" :class="{danger: G7Error}">
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
      <span v-if="G7Error" style="color: red">{{ G7Error }}</span>
    </div>

    <label>
      <span class="span-normal">G8. Briefly describe your experience with other types of
        evaluations</span>
    </label>
    <div class="">
      <input id="ee_desc_other_eval" v-model="G8" name="ee_desc_other_eval" :class="{danger: G8Error}"/>
      <span v-if="G8Error" style="color: red">{{ G8Error }}</span>
    </div>

    <h3>H. Experience with software</h3>

    <label>
      <span class="span-normal" >H1. What software are you experienced in using?</span>
    </label>
    <div class="">
      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(H1, $event)" id="soft_ms_word" name="soft_ms_word"
          value="Microsoft Word" />
        <label for="soft_ms_word">Microsoft Word</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(H1, $event)" id="soft_ms_excel" name="soft_ms_excel"
          value="Microsoft Excel" />
        <label for="soft_ms_excel">Microsoft Excel</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(H1, $event)" id="soft_ms_pp" name="soft_ms_pp"
          value="Microsoft Powerpoint" />
        <label for="soft_ms_pp">Microsoft Powerpoint</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(H1, $event)" id="soft_stata" name="soft_stata" value="STATA" />
        <label for="soft_stata">STATA</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(H1, $event)" id="soft_python" name="soft_python" value="Python" />
        <label for="soft_python">Python</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(H1, $event)" id="soft_maxqda" name="soft_maxqda" value="MAXQDA" />
        <label for="soft_maxqda">MAXQDA</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(H1, $event)" id="soft_gis" name="soft_gis" value="GIS Software" />
        <label for="soft_gis">GIS Software</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(H1, $event)" id="soft_odk" name="soft_odk" value="ODK" />
        <label for="soft_odk">ODK</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(H1, $event)" id="soft_survey_cto" name="soft_survey_cto"
          value="Survey CTO" />
        <label for="soft_survey_cto">Survey CTO</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(H1, $event)" id="soft_survey_solutions" name="soft_survey_solutions"
          value="Survey Solutions" />
        <label for="soft_survey_solutions">Survey Solutions</label><br />
      </div>

      <div class="checkbox-container">
        <input type="checkbox" @click="checkbox(H1, $event)" id="soft_other" name="soft_other" value="Other Specify" />
        <label for="soft_other">Other</label><br />
      </div>
      <span v-if="H1Error" style="color: red">{{ H1Error }}</span>
    </div>

    <label>
      <span class="span-normal">H2. If other software skills, please type here:</span>
    </label>
    <div class="">
      <input id="soft_other_specify" v-model="H2" name="soft_other_specify" :class="{danger: H2Error}"/>
      <span v-if="H2Error" style="color: red">{{ H2Error }}</span>
    </div>

    <label>
      <span class="span-normal">H3. Software skills: MS Word</span>
    </label>
    <div class="">
      <select name="soft_skill_ms_word" v-model="H3" id="soft_skill_ms_word" :class="{danger: H3Error}">
        <option value="None">None / Not Applicable</option>
        <option value="Basic">Basic</option>
        <option value="Intermediate">Intermediate</option>
        <option value="Advanced">Advanced</option>
        <option value="Expert">Expert</option>
      </select>
      <span v-if="H3Error" style="color: red">{{ H3Error }}</span>
    </div>

    <label>
      <span class="span-normal">H4. Software skills: MS Excel</span>
    </label>
    <div class="">
      <select name="soft_skill_ms_excel" v-model="H4" id="soft_skill_ms_excel" :class="{danger: H4Error}">
        <option value="None">None / Not Applicable</option>
        <option value="Basic">Basic</option>
        <option value="Intermediate">Intermediate</option>
        <option value="Advanced">Advanced</option>
        <option value="Expert">Expert</option>
      </select>
      <span v-if="H4Error" style="color: red">{{ H4Error }}</span>
    </div>

    <label>
      <span class="span-normal">H5. Software skills: MS PowerPoint</span>
    </label>
    <div class="">
      <select name="soft_skill_ms_pp" v-model="H5" id="soft_skill_ms_pp" :class="{danger: H5Error}">
        <option value="None">None / Not Applicable</option>
        <option value="Basic">Basic</option>
        <option value="Intermediate">Intermediate</option>
        <option value="Advanced">Advanced</option>
        <option value="Expert">Expert</option>
      </select>
      <span v-if="H5Error" style="color: red">{{ H5Error }}</span>
    </div>

    <label>
      <span class="span-normal">H6. Software skills: Stata</span>
    </label>
    <div class="">
      <select name="soft_skill_stata" v-model="H6" id="soft_skill_stata" :class="{danger: H6Error}">
        <option value="None">None / Not Applicable</option>
        <option value="Basic">Basic</option>
        <option value="Intermediate">Intermediate</option>
        <option value="Advanced">Advanced</option>
        <option value="Expert">Expert</option>
      </select>
      <span v-if="H6Error" style="color: red">{{ H6Error }}</span>
    </div>

    <label>
      <span class="span-normal">H7. Software skills: R</span>
    </label>
    <div class="">
      <select name="soft_skill_r" v-model="H7" id="soft_skill_r" :class="{danger: H7Error}">
        <option value="None">None / Not Applicable</option>
        <option value="Basic">Basic</option>
        <option value="Intermediate">Intermediate</option>
        <option value="Advanced">Advanced</option>
        <option value="Expert">Expert</option>
      </select>
      <span v-if="H7Error" style="color: red">{{ H7Error }}</span>
    </div>

    <label>
      <span class="span-normal">H8. Software skills: Python</span>
    </label>
    <div class="">
      <select name="soft_skill_python" v-model="H8" id="soft_skill_python" :class="{danger: H8Error}">
        <option value="None">None / Not Applicable</option>
        <option value="Basic">Basic</option>
        <option value="Intermediate">Intermediate</option>
        <option value="Advanced">Advanced</option>
        <option value="Expert">Expert</option>
      </select>
      <span v-if="H8Error" style="color: red">{{ H8Error }}</span>
    </div>

    <label>
      <span class="span-normal">H9. Software skills: MAXQDA</span>
    </label>
    <div class="">
      <select name="soft_skill_maxqda" v-model="H9" id="soft_skill_maxqda" :class="{danger: H9Error}">
        <option value="None">None / Not Applicable</option>
        <option value="Basic">Basic</option>
        <option value="Intermediate">Intermediate</option>
        <option value="Advanced">Advanced</option>
        <option value="Expert">Expert</option>
      </select>
      <span v-if="H9Error" style="color: red">{{ H9Error }}</span>
    </div>

    <label>
      <span class="span-normal">H10. Software skills: GIS software</span>
    </label>
    <div class="">
      <select name="soft_skill_gis" v-model="H10" id="soft_skill_gis" :class="{danger: H10Error}">
        <option value="None">None / Not Applicable</option>
        <option value="Basic">Basic</option>
        <option value="Intermediate">Intermediate</option>
        <option value="Advanced">Advanced</option>
        <option value="Expert">Expert</option>
      </select>
      <span v-if="H10Error" style="color: red">{{ H10Error }}</span>
    </div>

    <label>
      <span class="span-normal">H11. Software skills: ODK</span>
    </label>
    <div class="">
      <select name="soft_skill_odk" v-model="H11" id="soft_skill_odk" :class="{danger: H11Error}">
        <option value="None">None / Not Applicable</option>
        <option value="Basic">Basic</option>
        <option value="Intermediate">Intermediate</option>
        <option value="Advanced">Advanced</option>
        <option value="Expert">Expert</option>
      </select>
      <span v-if="H11Error" style="color: red">{{ H11Error }}</span>
    </div>

    <label>
      <span class="span-normal">H12. Software skills: SurveyCTO</span>
    </label>
    <div class="">
      <select name="soft_skill_surveycto" v-model="H12" id="soft_skill_surveycto" :class="{danger: H12Error}">
        <option value="None">None / Not Applicable</option>
        <option value="Basic">Basic</option>
        <option value="Intermediate">Intermediate</option>
        <option value="Advanced">Advanced</option>
        <option value="Expert">Expert</option>
      </select>
      <span v-if="H12Error" style="color: red">{{ H12Error }}</span>
    </div>

    <label>
      <span class="span-normal">H13. Software skills: Survey Solutions</span>
    </label>
    <div class="">
      <select name="soft_skill_ss" v-model="H13" id="soft_skill_ss" :class="{danger: H13Error}">
        <option value="None">None / Not Applicable</option>
        <option value="Basic">Basic</option>
        <option value="Intermediate">Intermediate</option>
        <option value="Advanced">Advanced</option>
        <option value="Expert">Expert</option>
      </select>
      <span v-if="H13Error" style="color: red">{{ H13Error }}</span>
    </div>

    <label>
      <span class="span-normal">H14. Software skills: Other software</span>
    </label>
    <div class="">
      <input id="soft_skill_other_soft" v-model="H14" name="soft_other_specify"  :class="{danger: H14Error}"/>
      <span v-if="H14Error" style="color: red">{{ H14Error }}</span>
    </div>

    <h3>I. Further information in support of your application</h3>

    <label>
      <span class="span-normal">I1. For which countries do you currently have a working permit?
      </span>
    </label>
    <div class="">
      <input id="wp_country" name="wp_country" v-model="I1"  :class="{danger: I1Error}"/>
      <span v-if="I1Error" style="color: red">{{ I1Error }}</span>
    </div>

    <label>
      <span class="span-normal">I2. Monthly salary expectations in local currency</span>
    </label>
    <div class="">
      <input id="exp_salary" name="exp_salary" v-model="I2"  :class="{danger: I2Error}"/>
      <span v-if="I2Error" style="color: red">{{ I2Error }}</span>
    </div>

    <label>
      <span class="span-normal" >I3. Please upload your CV in English here: * 
      (Note: Your application will not be accepted without this document.)</span>
      <span class="span-grey"></span>
    </label>
    <div class="">
      <input type="file" id="cv_file" name="cv_file" @change="handlefileI3($event)" />
      <span v-if="I3Error" style="color: red">{{ I3Error }}</span>
    </div>

    <label>
      <span class="span-normal" >I4. Please upload your letter of motivation in English here: 
        (Note: Your application will not be accepted without this document.)
      </span>
      
    </label>
    <div class="">
      <input type="file" id="motivation_file" name="motivation_file" @change="handlefileI4($event)"/>
      <span v-if="I4Error" style="color: red">{{ I4Error }}</span>
    </div>

    <label>
      <span class="span-normal">I5. If you are applying for a research-related position, please upload
        a writing sample or other documents required for the application. (e.g.
        report, paper, cover page) in English. For other positions, please
        upload relevant certificate(s).</span>
      <span class="span-grey">If you want to upload more than one document, please combine the
        documents into a single pdf file or compress them into a single zip
        archive. Please note that if you select several files for uploading,
        ONLY ONE of the attachments will be saved in the database.</span>
    </label>
    <div class="">
      <input type="file" id="writing_sample_file" name="writing_sample_file" @change="handlefileI5($event)" />
      <span v-if="I5Error" style="color: red">{{ I5Error }}</span>
    </div>

    <label>
      <span class="span-normal" >I6. I have read C4ED Data Protection Policy and I agree with it *</span>
    </label>
    <div class="">
      <div class="checkbox-container">
        <input type="checkbox" id="agreement" name="agreement" value="Yes, I Agree" v-model="I6" />
        <label for="agreement">Yes, I Agree</label><br />
      </div>
      <span v-if="I6Error" style="color: red">{{ I6Error }}</span>
    </div>

    <label>
      <span class="span-normal">I7. I have read information about data protection during job
        application process at C4ED and consent to processing of my personal
        data *</span>
      <span class="span-grey">Information about data protection during job application process can be
        found here:
        <a target="_blank"
          href="https://c4ed.org/information-about-data-protection-during-job-application-process">https://c4ed.org/information-about-data-protection-during-job-application-process</a></span>
    </label>
    <div class="">
      <select name="consent_data" id="consent_data" v-model="I7" :class="{danger: I7Error}">
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
      <span v-if="I7Error" style="color: red">{{ I7Error }}</span>
    </div>

    <label>
      <span class="span-normal">I8. I agree that C4ED processes and stores the personal data I
        submitted in the context of this application in its expert database
        permanently for the purpose of further contact in the future.</span>
      <span class="span-grey">This database is accessible to C4ED staff exclusively and is used
        solely for the purpose of conducting searches for qualified candidates
        to work on research projects. The applicant can request deletion of this
        data from the database at any moment. The request for deletion from the
        database or any other related request can be sent to the following email
        address: datenschutz@c4ed.org</span>
    </label>
    <div class="">
      <select name="consent_expert_data" id="consent_expert_data" v-model="I8" :class="{danger: I8Error}">
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
      <span v-if="I8Error" style="color: red">{{ I8Error }}</span>
    </div>

    <label>
      <span class="span-normal">I9. Any comments:</span>
    </label>
    <div class="">
      <input id="comments" name="comments" v-model="I9" />
    </div>

    <label>
      <span class="span-normal">I10. Where did you find this job ad?</span>
      <span class="span-grey">Please type in here: (Indeed.com, Monster.de etc)</span>
    </label>
    <div class="">
      <input id="job_site" name="job_site" />
    </div>

    <button @click="submit" class="custom-button">Submit Applicant Questionnaire</button>
  </div>
  <div class="modal" v-show="showModal" @click="closeModal">
    <div class="modal-wrapper">
      <transition name="modal" appear>
        <div class="modal-container" @click.stop>
          <button class="close-button" @click="closeModal">×</button>
          <div class="main-container">
            <h5>Your job application has been successfully submitted.</h5>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "LandingPage",
  data() {
    return {
      isLoading: false,
      showModal: false,
      countries: [
        "United States",
        "Canada",
        "Afghanistan",
        "Albania",
        "Algeria",
        "American Samoa",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua and/or Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia",
        "Bosnia and Herzegovina",
        "Botswana",
        "Bouvet Island",
        "Brazil",
        "British Indian Ocean Territory",
        "Brunei Darussalam",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cambodia",
        "Cameroon",
        "Cape Verde",
        "Cayman Islands",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Christmas Island",
        "Cocos (Keeling) Islands",
        "Colombia",
        "Comoros",
        "Congo",
        "Cook Islands",
        "Costa Rica",
        "Croatia (Hrvatska)",
        "Cuba",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "East Timor",
        "Ecudaor",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Ethiopia",
        "Falkland Islands (Malvinas)",
        "Faroe Islands",
        "Fiji",
        "Finland",
        "France",
        "France, Metropolitan",
        "French Guiana",
        "French Polynesia",
        "French Southern Territories",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Heard and Mc Donald Islands",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran (Islamic Republic of)",
        "Iraq",
        "Ireland",
        "Israel",
        "Italy",
        "Ivory Coast",
        "Jamaica",
        "Japan",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea, Democratic People's Republic of",
        "Korea, Republic of",
        "Kosovo",
        "Kuwait",
        "Kyrgyzstan",
        "Lao People's Democratic Republic",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libyan Arab Jamahiriya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macau",
        "Macedonia",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Micronesia, Federated States of",
        "Moldova, Republic of",
        "Monaco",
        "Mongolia",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "Netherlands Antilles",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Niue",
        "Norfork Island",
        "Northern Mariana Islands",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Pitcairn",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Reunion",
        "Romania",
        "Russian Federation",
        "Rwanda",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Georgia South Sandwich Islands",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "St. Helena",
        "St. Pierre and Miquelon",
        "Sudan",
        "Suriname",
        "Svalbarn and Jan Mayen Islands",
        "Swaziland",
        "Sweden",
        "Switzerland",
        "Syrian Arab Republic",
        "Taiwan",
        "Tajikistan",
        "Tanzania, United Republic of",
        "Thailand",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks and Caicos Islands",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States minor outlying islands",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Vatican City State",
        "Venezuela",
        "Vietnam",
        "Virigan Islands (British)",
        "Virgin Islands (U.S.)",
        "Wallis and Futuna Islands",
        "Western Sahara",
        "Yemen",
        "Yugoslavia",
        "Zaire",
        "Zambia",
        "Zimbabwe",
      ],



      firstName: "",
      firstNameError: "",

      middleName: "",
      middleNameError: "",

      surName: "",
      surNameError: "",

      email: "",
      emailError: "",

      gender: "",
      genderError: "",

      DOB: "",
      DOBError: "",

      A7: "",
      A7Error: "",

      A8: "",
      A8Error: "",

      A9: "",
      A9Error: "",

      B1: "",
      B1Error: "",

      B2: "",
      B2Error: "",

      B3: "",
      B3Error: "",

      B4: "",
      B4Error: "",

      B5: "",
      B5Error: "",

      B6: "",
      B6Error: "",

      B7: [],
      B7Error: "",

    

      B8: "",
      B8Error: "",

      C1: [],
      C1Error: "",

      C2: [],
      C2Error: "",

      C3: "",
      C3Error: "",

      C4: "",
      C4Error: "",

      C5: "",
      C5Error: "",

      C6: "",
      C6Error: "",

      D1: [],
      D1Error: "",

      D2: "",
      D2Error: "",

      D3: "",
      D3Error: "",

      D4: "",
      D4Error: "",

      D5: "",
      D5Error: "",

      D6: "",
      D6Error: "",

      D7: "",
      D7Error: "",

      D8: "",
      D8Error: "",

      D9: "",
      D9Error: "",

      D10: [],
      D10Error: "",

      E1: "",
      E1Error: "",

      E2: "",
      E2Error: "",

      F1: "",
      F1Error: "",

      F2: "",
      F2Error: "",

      F3: [],
      F3Error: "",

      F4: "",
      F4Error: "",

      G1: "",
      G1Error: "",

      G2: "",
      G2Error: "",

      G3: "",
      G3Error: "",

      G4: "",
      G4Error: "",

      G5: "",
      G5Error: "",

      G6: "",
      G6Error: "",

      G7: "",
      G7Error: "",

      G8: "",
      G8Error: "",

      H1: [],
      H1Error: "",

      H2: "",
      H2Error: "",

      H3: "",
      H3Error: "",

      H4: "",
      H4Error: "",

      H5: "",
      H5Error: "",

      H6: "",
      H6Error: "",

      H7: "",
      H7Error: "",

      H8: "",
      H8Error: "",

      H9: "",
      H9Error: "",

      H10: "",
      H10Error: "",

      H11: "",
      H11Error: "",

      H12: "",
      H12Error: "",

      H13: "",
      H13Error: "",

      H14: "",
      H14Error: "",

      I1: "",
      I1Error: "",

      I2: "",
      I2Error: "",

      I3: "",
      I3Error: "",

      I4: "",
      I4Error: "",

      I5: "",
      I5Error: "",

      I6: "",
      I6Error: "",

      I7: "",
      I7Error: "",

      I8: "",
      I8Error: "",

      I9: "",
      I9Error: "",

      I10: "",
      I10Error: "",
      A7Disable: false
    };
  },

  mounted() {
    const jobId = this.$route.query.job_id
    if (jobId) {
      this.A7 = jobId
      this.A7Disable = true
    }
  },
  methods: {
    closeModal(){
      this.showModal = false;
      window.location.reload();
    },
    submit() {
      var submitStatus = true;
      if (this.surName == "") {
        this.surNameError = "Surname Name is required!";
        submitStatus = false;
      } else {
        this.surNameError = "";
      }

      // first name

      if (this.firstName == "") {
        this.firstNameError = "First Name is required!";
        submitStatus = false;
      } else {
        this.firstNameError = "";
      }

      // email

      if (this.email == "") {
        this.emailError = "E-mail is required!";
        submitStatus = false;
      } else if (
        !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
          this.email
        )
      ) {
        this.emailError = "E-mail format is invalid!";
        submitStatus = false;
      } else {
        this.emailError = "";
      }

      // Gender

      if (this.gender == "") {
        this.genderError = "Gender is required!";
        submitStatus = false;
      } else {
        this.genderError = "";
      }

      // DOB

      if (this.DOB == "") {
        this.DOBError = "Date of birth is required!";
        submitStatus = false;
      } else {
        this.DOBError = "";
      }

      // A7

      if (this.A7 == "") {
        this.A7Error = "Job ID is required!";
        submitStatus = false;
      } else {
        this.A7Error = "";
      }

      // A8

      if (this.A8 == "") {
        this.A8Error = "Nationality is required!";
        submitStatus = false;
      } else {
        this.A8Error = "";
      }

      // A9

      if (this.A9 == "") {
        this.A9Error = "Currently living (city, country) is required!";
        submitStatus = false;
      } else {
        this.A9Error = "";
      }

      // B1

      if (this.B1 == "") {
        this.B1Error = "English language skills selection is required!";
        submitStatus = false;
      } else {
        this.B1Error = "";
      }

      // B2

      if (this.B2 == "") {
        this.B2Error = "Spanish language skills selection is required!";
        submitStatus = false;
      } else {
        this.B2Error = "";
      }

      // B3

      if (this.B3 == "") {
        this.B3Error = "French language skills selection is required!";
        submitStatus = false;
      } else {
        this.B3Error = "";
      }

      // B4

      if (this.B4 == "") {
        this.B4Error = "Portuguese language skills selection is required!";
        submitStatus = false;
      } else {
        this.B4Error = "";
      }

      // B5

      if (this.B5 == "") {
        this.B5Error = "German language skills selection is required!";
        submitStatus = false;
      } else {
        this.B5Error = "";
      }

      // B6

      if (this.B6 == "") {
        this.B6Error = "Russian language skills selection is required!";
        submitStatus = false;
      } else {
        this.B6Error = "";
      }

      // B8

      if (this.B7.includes('other') && this.B8 == "") {
        this.B8Error = "Specify other in additional langauge skills!";
        submitStatus = false;
      } else {
        this.B8Error = "";
      }

      // C1

      if (this.C1 == "") {
        this.C1Error = "Highest degree selection is required!";
        submitStatus = false;
      } else {
        this.C1Error = "";
      }

      // C2

      if (this.C2 == "") {
        this.C2Error = "Discipline degree selection is required!";
        submitStatus = false;
      } else {
        this.C2Error = "";
      }

      // C3

      if (this.C2.includes('Other Discipline') && this.C3 == "") {
        this.C3Error = "Specify other discipline!";
        submitStatus = false;
      } else {
        this.C3Error = "";
      }

      // C4

      if (this.C4 == "") {
        this.C4Error = "University name is required!";
        submitStatus = false;
      } else {
        this.C4Error = "";
      }

      // C5

      if (this.C5 == "") {
        this.C5Error = "Completed degree date is required!";
        submitStatus = false;
      } else {
        this.C5Error = "";
      }

      // D1

      if (this.D1 == "") {
        this.D1Error = "Professional Experience selection is required!";
        submitStatus = false;
      } else {
        this.D1Error = "";
      }

      // D2

      if (this.D2 == "") {
        this.D2Error = "Work experience country 1 is required!";
        submitStatus = false;
      } else {
        this.D2Error = "";
      }

      // D2

      if (this.D2 == "") {
        this.D2Error = "Work experience country 1 is required!";
        submitStatus = false;
      } else {
        this.D2Error = "";
      }

      // D10

      if (this.D10 == "") {
        this.D10Error = "Experience area selection is required!";
        submitStatus = false;
      } else {
        this.D10Error = "";
      }

      // E1

      if (this.E1 == "") {
        this.E1Error = "Selection is required!";
        submitStatus = false;
      } else {
        this.E1Error = "";
      }

      // E2

      if (this.E2 == "") {
        this.E2Error = "Selection is required!";
        submitStatus = false;
      } else {
        this.E2Error = "";
      }

      // F1

      if (this.F1 == "") {
        this.F1Error = "Selection is required!";
        submitStatus = false;
      } else {
        this.F1Error = "";
      }

      // F2

      if (this.F2 == "") {
        this.F2Error = "Largest survey experience is required!";
        submitStatus = false;
      } else {
        this.F2Error = "";
      }

      // F3

      if (this.F3.includes('Other (specify below)') && this.F4 == "") {
        this.F4Error = "Specify, Other method(s) of data collection!";
        submitStatus = false;
      } else {
        this.F4Error = "";
      }

      // G1

      if (this.G1 == "") {
        this.G1Error = "Selection is required!";
        submitStatus = false;
      } else {
        this.G1Error = "";
      }

      // G2

      if (this.G1 == "Yes" && this.G2 == "") {
        this.G2Error = "Experience with impact evaluations is required!";
        submitStatus = false;
      } else {
        this.G2Error = "";
      }

      // G3

      if (this.G3 == "") {
        this.G3Error = "Selection is required!";
        submitStatus = false;
      } else {
        this.G3Error = "";
      }

      // G4

      if (this.G3 == "Yes" && this.G4 == "") {
        this.G4Error = "Experience with process evaluation is required!";
        submitStatus = false;
      } else {
        this.G4Error = "";
      }

      // G5

      if (this.G5 == "") {
        this.G5Error = "Selection is required!";
        submitStatus = false;
      } else {
        this.G5Error = "";
      }


      // G6

      if (this.G5 == "Yes" && this.G6 == "") {
        this.G6Error = "Experience with formative evaluations is required!";
        submitStatus = false;
      } else {
        this.G6Error = "";
      }

      // G7

      if (this.G7 == "") {
        this.G7Error = "Selection is required!";
        submitStatus = false;
      } else {
        this.G7Error = "";
      }

      // G8

      if (this.G7 == "Yes" && this.G8 == "") {
        this.G8Error = "Experience with other evaluations is required!";
        submitStatus = false;
      } else {
        this.G8Error = "";
      }

      // H1

      if (this.H1 == "") {
        this.H1Error = "Software experience selection is required!";
        submitStatus = false;
      } else {
        this.H1Error = "";
      }

      // H2

      if (this.H1.includes('Other Specify') && this.H2 == "") {
        this.H2Error = "Specify other software skills!";
        submitStatus = false;
      } else {
        this.H2Error = "";
      }


      // H3

      if (this.H3 == "") {
        this.H3Error = "Selection is required!";
        submitStatus = false;
      } else {
        this.H3Error = "";
      }

      // H4

      if (this.H4 == "") {
        this.H4Error = "Selection is required!";
        submitStatus = false;
      } else {
        this.H4Error = "";
      }

      // H5

      if (this.H5 == "") {
        this.H5Error = "Selection is required!";
        submitStatus = false;
      } else {
        this.H5Error = "";
      }

      // H6

      if (this.H6 == "") {
        this.H6Error = "Selection is required!";
        submitStatus = false;
      } else {
        this.H6Error = "";
      }

      // H7

      if (this.H7 == "") {
        this.H7Error = "Selection is required!";
        submitStatus = false;
      } else {
        this.H7Error = "";
      }


      // H8

      if (this.H8 == "") {
        this.H8Error = "Selection is required!";
        submitStatus = false;
      } else {
        this.H8Error = "";
      }

      // H9

      if (this.H9 == "") {
        this.H9Error = "Selection is required!";
        submitStatus = false;
      } else {
        this.H9Error = "";
      }

      // H10

      if (this.H10 == "") {
        this.H10Error = "Selection is required!";
        submitStatus = false;
      } else {
        this.H10Error = "";
      }

      // H11

      if (this.H11 == "") {
        this.H11Error = "Selection is required!";
        submitStatus = false;
      } else {
        this.H11Error = "";
      }

      // H12

      if (this.H12 == "") {
        this.H12Error = "Selection is required!";
        submitStatus = false;
      } else {
        this.H12Error = "";
      }

      // H13

      if (this.H13 == "") {
        this.H13Error = "Selection is required!";
        submitStatus = false;
      } else {
        this.H13Error = "";
      }

      // I1

      if (this.I1 == "") {
        this.I1Error = "Work permit country is required!";
        submitStatus = false;
      } else {
        this.I1Error = "";
      }

      // I2

      if (this.I2 == "") {
        this.I2Error = "Monthly salary expectation is required!";
        submitStatus = false;
      } else {
        this.I2Error = "";
      }

      // I3

      if (this.I3 == "") {
        this.I3Error = "CV is required!";
        submitStatus = false;
      } else if (this.I3.size > 10240 * 1024) {
        this.I3Error = "CV: File size should be less than 10MB !";
        submitStatus = false;
      }else{
        this.I3Error = "";
      }

      // I6

      if (this.I6 == "") {
        this.I6Error = "Consent is required!";
        submitStatus = false;
      } else {
        this.I6Error = "";
      }

      // I4

      if (this.I4 == "") {
        this.I4Error = "Letter of motivation is required!";
        submitStatus = false;
      } else if (this.I4.size > 10240 * 1024) {
        this.I4Error = "Letter of motivation: File size should be less than 10MB !";
        submitStatus = false;
      }else{
        this.I4Error = "";
      }

      // I5

      if (this.I5.size > 10240 * 1024) {
        this.I5Error = "Writing sample: File size should be less than 10MB !";
        submitStatus = false;
      }else{
        this.I5Error = "";
      }


      // I7

      if (this.I7 == "" || this.I7 == "No") {
        this.I7Error = "Selection or agreeing consent 'Yes' is required!";
        submitStatus = false;
      } else {
        this.I7Error = "";
      }

      // I8

      if (this.I8 == "" || this.I8 == "No") {
        this.I8Error = "Selection or agreeing consent 'Yes' for data storage policy is required!";
        submitStatus = false;
      } else {
        this.I8Error = "";
      }


      // Further validations go here

      // --

      if (submitStatus === false) {
        return submitStatus;
      }
      // this.closeModal();
      this.isLoading = true;
      this.showModal = true;


      let formdata = new FormData();
      formdata.append("firstName", this.firstName);
      formdata.append("middleName", this.middleName);
      formdata.append("surName", this.surName);
      formdata.append("email", this.email);
      formdata.append("gender", this.gender);
      formdata.append("DOB", this.DOB);
      formdata.append("DOB", this.DOB);
      formdata.append("DOB", this.DOB);
      formdata.append("A7", this.A7);
      formdata.append("A8", this.A8);
      formdata.append("A9", this.A9);
      formdata.append("B1", this.B1);
      formdata.append("B2", this.B2);
      formdata.append("B3", this.B3);
      formdata.append("B4", this.B4);
      formdata.append("B5", this.B5);
      formdata.append("B6", this.B6);
      formdata.append("B7", this.B7);
      formdata.append("B8", this.B8);
      formdata.append("C1", this.C1);
      formdata.append("C2", this.C2);
      formdata.append("C3", this.C3);
      formdata.append("C4", this.C4);
      formdata.append("C5", this.C5);
      formdata.append("C6", this.C6);
      formdata.append("D1", this.D1);
      formdata.append("D2", this.D2);
      formdata.append("D3", this.D3);
      formdata.append("D4", this.D4);
      formdata.append("D5", this.D5);
      formdata.append("D6", this.D6);
      formdata.append("D7", this.D7);
      formdata.append("D8", this.D8);
      formdata.append("D9", this.D9);
      formdata.append("D10", this.D10);
      formdata.append("E1", this.E1);
      formdata.append("E2", this.E2);
      formdata.append("F1", this.F1);
      formdata.append("F2", this.F2);
      formdata.append("F3", this.F3);
      formdata.append("F4", this.F4);
      formdata.append("G1", this.G1);
      formdata.append("G2", this.G2);
      formdata.append("G3", this.G3);
      formdata.append("G4", this.G4);
      formdata.append("G5", this.G5);
      formdata.append("G6", this.G6);
      formdata.append("G7", this.G7);
      formdata.append("G8", this.G8);
      formdata.append("H1", this.H1);
      formdata.append("H2", this.H2);
      formdata.append("H3", this.H3);
      formdata.append("H4", this.H4);
      formdata.append("H5", this.H5);
      formdata.append("H6", this.H6);
      formdata.append("H7", this.H7);
      formdata.append("H8", this.H8);
      formdata.append("H9", this.H9);
      formdata.append("H10", this.H10);
      formdata.append("H11", this.H11);
      formdata.append("H12", this.H12);
      formdata.append("H13", this.H13);
      formdata.append("H14", this.H14);
      formdata.append("I1", this.I1);
      formdata.append("I2", this.I2);
      formdata.append("I3", this.I3);
      formdata.append("I4", this.I4);
      formdata.append("I5", this.I5);
      formdata.append("I6", this.I6);
      formdata.append("I7", this.I7);
      formdata.append("I8", this.I8);
      formdata.append("I9", this.I9);
      formdata.append("I10", this.I10);
      const headers = {
        'Content-Type': 'multipart/form-data'
      };
      axios.post("https://api.careers.c4ed.org/job_application/", formdata, { headers })
        .then(response => {
          this.isLoading = false;
          this.showModal = true;
          this.data.reset();
          return true;
        })
        .catch((e) => {
          return false;
        });
    },
    checkbox(data_type, e) {
      if (e.target.checked) {
        data_type.push(e.target.value);
      } else {
        const index = data_type.indexOf(e.target.value);
        if (index > -1) {
          // only splice array when item is found
          data_type.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
    },
    handlefileI3(e) {
      this.I3 = e.target.files[0];
    },
    handlefileI4(e) {
      this.I4 = e.target.files[0];
    },
    handlefileI5(e) {
      this.I5 = e.target.files[0];
    },
  },
};
</script>

<style scoped>
body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  background: #fff;
  width: 450px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  margin: 0 auto;
  padding: 20px 30px;
  transform-origin: center;
  opacity: 0;
  animation: modalFadeIn 0.5s ease-out forwards;
}

@keyframes modalFadeIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.modal-footer {
  margin-top: 15px;
}

.modal-enter,
.modal-leave-to {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-to .modal-container {
  transform: scale(1.1);
}

.main-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.main-container {
  position: relative;
}

.close-button {
  padding: 3px 5px; /* Adjust padding for smaller button */
  font-size: 14px; /* Adjust font size for smaller button */
  position: absolute;
  top: 0;
  right: 0;
  margin: 5px 5px;
  /* background-color: #e74c3c; */
}
.close-button:hover {
  background-color: #3498db;
  color: #eaecee;
}

/* Responsive Styles for Close Button */
@media (max-width: 600px) {
  .close-button {
    font-size: 12px; /* Adjust font size for smaller screens */
    padding: 6px 10px; /* Adjust padding for smaller screens */
  }
}
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, .8), rgba(0, 0, 0, .8));

  background: -webkit-radial-gradient(rgba(20, 20, 20, .8), rgba(0, 0, 0, .8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.container {
  margin: auto;
  width: 50%;
  padding: 10px;
}

h1 {
  font-size: 24px;
}

h3 {
  font-size: 16px;
}

label {
  display: block;
  width: 100%;
  text-align: left;
  margin-top: 5px;
  font-weight: bold;
  color: #333;
  /* You can customize the color */
}

.danger {
  border: red 2px solid;
}

span {
  /* display: block; */
  font-size: 14px;
  width: 100%;
}

div {
  text-align: left;
  margin-top: 0px;
  /* Adjust the margin as needed */
}

input,
select {
  width: 100%;
  height: 30px;
  padding: 5px;
  margin-top: 10px;
  background-color: #eaecee;
  border-radius: 8px;
  border: none;
}

option {
  padding: 5px 5px;
  font-size: 14px;
}

.checkbox-container {
  margin: 0px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.checkbox-container input[type="checkbox"] {
  width: 10%;
  height: 14px;
  margin: 5px 0px 5px 0px;
}

button,
input[type="file"] {
  display: inline-block;
  margin: 20px 0px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: 2px solid #3498db;
  /* Border color */
  color: #3498db;
  /* Text color */
  background-color: #ffffff;
  /* Background color */
  border-radius: 5px;
  /* Rounded corners */
  transition: background-color 0.3s, color 0.3s;
  /* Smooth transition */
}

/* Hover effect */
.custom-button:hover {
  background-color: #3498db;
  color: #ffffff;
}

@media only screen and (max-width: 431px) {
    .modal-container {
      width: 80%;
      max-width: none;
    }
  }


/* Responsive styles */
/*
@media screen and (max-width: 600px) {

  label,
  span,
  input,
  select {
    width: 100%;
  }
}

*/
</style>
